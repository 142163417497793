<script setup>
import { ref, defineExpose } from 'vue';
import SendFeedbackModal from '@/components/SendFeedbackModal.vue';
import SmileIcon from '@/assets/icons/smile.svg';
import HostingerLogo from '@/assets/icons/hostinger-logo.svg';
import HostingerLogoFull from '@/assets/icons/hostinger-logo-full.svg';

const MEDIA_MOBILE_BREAKPOINT = 704;

const isMobile = window.screen.width <= MEDIA_MOBILE_BREAKPOINT;

const isSendFeedbackModalOpen = ref(false);

defineExpose({ isSendFeedbackModalOpen });
</script>

<template>
  <header class="header">
    <HostingerLogo v-if="isMobile" class="header__logo" />
    <HostingerLogoFull v-else class="header__logo" />
    <slot></slot>
    <div
      @click="isSendFeedbackModalOpen = true"
      class="header__share-feedback-button"
    >
      <SmileIcon />
      <p class="header__share-feedback-text">Help us improve</p>
    </div>
  </header>
  <SendFeedbackModal
    v-if="isSendFeedbackModalOpen"
    @close="isSendFeedbackModalOpen = false"
  />
</template>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 72px;
  padding: 9px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  background-color: var(--light);
  border-bottom: 1px solid var(--gray-border);

  &__logo {
    fill: var(--dark);
  }

  &__share-feedback-button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  &__share-feedback-text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: var(--dark);
  }
}

@media screen and (max-width: 1012px) {
  .header {
    &__right-desktop {
      display: none;
    }

    &__right-mobile {
      display: block;
    }
  }
}

@media screen and (max-width: 704px) {
  .header {
    padding: 22px 24px;

    &__left {
      justify-content: space-between;
      flex-grow: 1;
      margin-right: 24px;
    }

    &__logo-container {
      padding: 10px 0px;
    }

    &__separator {
      display: none;
    }

    &__share-feedback-text {
      display: none;
    }
  }
}
</style>
